import { apiClient } from '@/lib/api-client'
import type { StudentAssignmentProgression } from '@/lib/use-student-assignment'
import { toContentGradeFromGradePosition } from '@wl/activity'
import type { StudentLessonProgressionRepresentation } from '@wl/api-client'
import { StudentInventoryCategory } from '@wl/api-client'
import cuid from 'cuid'
import invariant from 'tiny-invariant'

export interface StudentAvatar {
  image: string | undefined
  owned: boolean
  cost: number | undefined
}

export interface StudentProfile {
  avatar: string
  classroomId: string | undefined
  name: string
  userId: string
  contentGrade: string
  isDemoStudent?: boolean
}
export interface StudentMetadata {
  lessonsStepId?: string | null
  onboardingStepId?: string | null
}

export async function getStudent(
  studentId: string,
  init?: RequestInit,
): Promise<StudentProfile> {
  const { data: student } =
    await apiClient.studentsApi.apiV1StudentsStudentIdGet({ studentId }, init)

  invariant(student?.userId, 'Student is required')

  return {
    avatar: student.avatar == '' ? 'avatar-001' : student.avatar,
    classroomId: student.classroom?.classId,
    name: getDisplayName(student),
    userId: student.userId,
    contentGrade: toContentGradeFromGradePosition(
      student.gradePosition,
      student.gradeSetId,
    ),
    isDemoStudent: student.isDemoStudent,
  }
}

export async function getStudentPurchases(
  studentId: string,
  init?: RequestInit,
) {
  const [{ data: purchases }, { data: allAvatars }] = await Promise.all([
    apiClient.studentsApi.apiV1StudentsStudentIdPurchasesGet(
      { studentId },
      init,
    ),
    apiClient.avatarsApi.apiV1AvatarsGet(init),
  ])

  const avatarList: StudentAvatar[] = []

  for (const avatar of allAvatars) {
    const isFreeAvatar = avatar.price === 0
    const doesOwnAvatar = purchases.find(({ id }) => avatar.id === id)

    if (isFreeAvatar || doesOwnAvatar) {
      avatarList.push({ image: avatar.id, owned: true, cost: avatar.price })
    } else {
      avatarList.push({ image: avatar.id, owned: false, cost: avatar.price })
    }
  }

  return avatarList
}

export async function getStudentLessonProgress(
  studentId: string,
  init?: RequestInit,
): Promise<StudentLessonProgressionRepresentation | undefined> {
  const { data } =
    await apiClient.lessonsApi.apiV1MyLessonsStudentIdProgressionGet(
      { studentId },
      init,
    )
  return data
}

interface GetStudentAssignmentProgressParams {
  studentId: string
  assignmentId: string
}

export const getStudentAssignmentProgress = async (
  { studentId, assignmentId }: GetStudentAssignmentProgressParams,
  init?: RequestInit,
): Promise<StudentAssignmentProgression | null> => {
  const { data } =
    await apiClient.lessonsApi.apiV1MyLessonsStudentIdProgressionGet(
      { studentId, assignmentId },
      init,
    )
  return data
    ? {
        assignmentId,
        lastSavedProgressionStep: data.lessonStepId,
      }
    : null
}

export interface LessonProgressionOption {
  lessonStepId?: string
  onboardingStepId?: string | null
  assignmentId?: string
}

export async function putStudentLessonProgress(
  studentId: string,
  progressionData: LessonProgressionOption,
) {
  const { data } =
    await apiClient.lessonsApi.apiV1MyLessonsStudentIdProgressionPut({
      studentId,
      studentLessonProgressionRequest: {
        eventId: cuid(),
        ...progressionData,
      },
    })
  return data ?? {}
}

export interface AssignmentParams {
  assignmentId: string
  lessonId?: string
}

export interface ActivityResultOptions {
  activityId: string
  rewardIds: string[]
  passCriteriaIds: string[]
  coinsEarned: number
}

export async function postActivityResult(
  studentId: string,
  activityData: ActivityResultOptions,
  assignmentParams?: AssignmentParams | undefined,
) {
  const { data } =
    await apiClient.lessonsApi.apiV1MyLessonsStudentIdActivityResultsPost({
      studentId,
      activityResultEventRequestRepresentation: {
        eventId: cuid(),
        ...activityData,
        ...assignmentParams,
      },
    })
  return data
}

export interface QuizResultOptions {
  activityId: string
  rewardIds: string[]
  passCriteriaIds: string[]
  correctCount: number
  totalCount: number
  studentAnswers: string[]
  correctAnswers: string[]
  attempt: number
  coinsEarned: number
}

export async function postQuizResult(
  studentId: string,
  quizResult: QuizResultOptions,
  assignmentParams?: AssignmentParams | undefined,
) {
  const { data } =
    await apiClient.lessonsApi.apiV1MyLessonsStudentIdQuizResultsPost({
      studentId,
      quizResultEventRequestRepresentation: {
        eventId: cuid(),
        ...quizResult,
        ...assignmentParams,
      },
    })
  return data
}

export interface WritingResultOptions {
  activityId: string
  rewardIds: string[]
  passCriteriaIds: string[]
  studentAnswers: string[]
  coinsEarned: number
}

export async function postWritingResult(
  studentId: string,
  writingResult: WritingResultOptions,
  assignmentParams?: AssignmentParams | undefined,
) {
  const { data } =
    await apiClient.lessonsApi.apiV1MyLessonsStudentIdWritingResultsPost({
      studentId,
      writingResultEventRequestRepresentation: {
        eventId: cuid(),
        ...writingResult,
        ...assignmentParams,
      },
    })
  return data
}

interface PutAvatarOptions {
  studentId: string
  avatar: string
}

export async function putAvatar(
  { studentId, avatar }: PutAvatarOptions,
  init?: RequestInit,
) {
  return apiClient.studentsApi.apiV1StudentsStudentIdAvatarPut(
    {
      studentId,
      updateStudentAvatarModel: {
        avatar,
      },
    },
    init,
  )
}

interface PostAvatarPurchaseOptions {
  studentId: string
  avatar: string
}

export async function postAvatarPurchase(
  { avatar, studentId }: PostAvatarPurchaseOptions,
  init?: RequestInit,
) {
  return await apiClient.studentsApi.apiV1StudentsStudentIdPurchasesPost(
    {
      studentId,
      studentPurchaseRequest: {
        id: avatar,
        eventId: cuid(),
        category: StudentInventoryCategory.Avatar,
      },
    },
    init,
  )
}

export function getDisplayName(
  {
    firstName: rawFirstName,
    lastName: rawLastName,
  }: {
    firstName?: string | null | undefined
    lastName?: string | null | undefined
  } = {},
  fallback = 'Student',
): string {
  const firstName = rawFirstName?.trim()
  const lastName = rawLastName?.trim()

  if (!firstName) return fallback
  if (!lastName) return firstName
  return `${firstName} ${lastName.charAt(0)}.`
}

export interface StudentAssignment {
  precinct: string
  taskActivityId: string
  assignmentId: string
  dueDate: Date
  lessonTitle?: string
  lessonName?: string
  lastSavedProgressStep?: string | undefined | null
}

export const getLatestTask = async (studentId: string, init?: RequestInit) => {
  const { data } =
    await apiClient.studentsApi.apiV1StudentsStudentIdCurrenttaskGet(
      { studentId },
      init,
    )

  if (data?.precinct === 'lessons') {
    return {
      precinct: data.precinct,
      taskActivityId: data.taskActivityId,
      assignmentId: data.assignmentId ?? '',
      dueDate: data.endAtUtc ?? new Date(),
    }
  }
  return
}
